import React from "react";
import classNames from "classnames";
import withFormHandlers from "../withFormHandlers";
import BaseInput from "../BaseInput";
import Label from "../../Label";

const Checkbox = (props) => {
  const {
    getErrors,
    isPristine,
    isValid,
    isDirty,
    appearance,
    inputSize,
    baseInputProps,
    help,
    label,
    checkedValue,
    ...rest
  } = props;

  if (props.type === "hidden") {
    return <input {...rest} />;
  }

  const classes = classNames("input-checkbox", {
    "input--invalid": getErrors().length,
    "input--valid": isValid(),
    [`input--${inputSize}`]: inputSize,
  });

  return (
    <BaseInput
      errorAppearance="start"
      input={
        <div
          className={classNames("checkbox", {
            [`checkbox--${appearance}`]: appearance,
          })}
        >
          <input type="checkbox" className={classes} {...rest} />
          <Label required={props.required} htmlFor={props.id}>
            {label}
          </Label>
        </div>
      }
      help={help}
      errors={getErrors()}
      {...baseInputProps}
    />
  );
};

const valueOrDefault = (value, defaultValue) => {
  return typeof value !== "undefined" ? value : defaultValue;
};
const valueIfChecked = (isChecked, value) => {
  return isChecked ? valueOrDefault(value, true) : valueOrDefault(null, false);
};
export default withFormHandlers({
  getValueFromEvent: (event, { checkedValue }) =>
    valueIfChecked(event.target.checked, checkedValue),
  getValueFromProps: ({ value, checked, checkedValue }) => {
    const isChecked =
      typeof checked === "boolean"
        ? checked
        : typeof value === "boolean"
          ? value
          : false;

    return valueIfChecked(isChecked, checkedValue);
  },
  getPropsFromValue: (value) => ({ checked: value || false }),
})(Checkbox);
