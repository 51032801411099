import React from "react";
import { injectIntl } from "react-intl";
import compose from "recompose/compose";
import mapProps from "recompose/mapProps";
import Select from "../Select";

const SearchSortSelect = (props) => {
  return <Select {...props} options={props.options} />;
};

export default compose(
  injectIntl,
  mapProps(({ placeholder, options, ...props }) => ({
    ...props,
    options: options.map((option) => ({
      label: option.label ? option.label : placeholder,
      value: option.name,
    })),
    placeholder: placeholder,
  }))
)(SearchSortSelect);
