import React from "react";
import { defineMessages, useIntl } from "react-intl";

const messages = defineMessages({
  errorFile: {
    id: "components.atoms.Form.Input.FileInput.errorFile",
    defaultMessage:
      "The file you are trying to add exceeds the authorised size limit of 50 MB.",
  },
});

const FileInput = ({ text, error, ...props }) => {
  const intl = useIntl();
  return (
    <div className="input-file">
      <input type="file" {...props} />
      <label className="input-file__label" htmlFor={props.id}>
        <span>{text}</span>
      </label>
      {error && (
        <div className="input-file__error">
          {intl.formatMessage(messages.errorFile)}
        </div>
      )}
    </div>
  );
};

export default FileInput;
